import {useEffect, useState, useRef} from 'react'
import {useDebounce} from './useDebounce'
import axios from 'axios'
import Heatmap from './HeatMap'
import Swal from 'sweetalert2'
import TransferList from './TransferList'
import './App.css' // Stil için ayrı bir CSS dosyası
import {Range} from 'react-range'
import {useDropzone} from 'react-dropzone'

function App() {
  const [searchHpo, setSearchHpo] = useState(false)
  const [searchSample, setSearchSample] = useState(false)
  const queryParameters = new URLSearchParams(window.location.search)
  const token = queryParameters.get('token')
  const [hpoList, setHpoList] = useState([])
  const [sampleList, setSampleList] = useState([])
  const [checked, setChecked] = useState([])
  const heatMapRef = useRef(null)
  const [progressBar, setProgressBar] = useState(0)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [hpoTerms, setHpoTerms] = useState('')
  const debouncedPhenoType = useDebounce(hpoTerms, 500)
  const [phenoTypeFilterIsActive, setPhenoTypeFilterIsActive] = useState(false)
  const [phenoTypeFilters, setPhenoTypeFilters] = useState([])
  const [sampleFilters, setSampleFilters] = useState([])
  const [sampleTypeFilterIsActive, setSampleTypeFilterIsActive] = useState(false)
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])
  const [step, setStep] = useState(1)
  const [mafData, setMafData] = useState(null)
  const [isRendered, setIsRendered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [detScore, setDetScore] = useState(2.5) // Tek bir değer
  const [genePool, setGenePool] = useState([])
  const [classificationTypes, setClassificationTypes] = useState([
    'Synonymous',
    'In Frame Indel',
    'Other Non-Synonymous',
    'Missense',
    'Splice Site',
    'Frame Shift',
    'Nonsense',
  ])

  const min = 0
  const max = 5
  const handleScoreChange = (e) => {
    const value = e.target.value
    if (value === '') {
      setDetScore(value)
      return
    }

    const parsedValue = parseFloat(value)
    if (!isNaN(parsedValue) && parsedValue >= min && parsedValue <= max) {
      setDetScore(parsedValue)
    }
  }

  const [selectedTypes, setSelectedTypes] = useState([])
  const handleCheckboxChange = (type) => {
    setSelectedTypes((prevSelected) =>
      prevSelected.includes(type)
        ? prevSelected.filter((item) => item !== type)
        : [...prevSelected, type]
    )
  }
  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const convertToTxtFile = (data) => {
    const rows = data.split('\n').filter((row) => row.trim() !== '')

    let txtContent = ''
    rows.forEach((row) => {
      txtContent += `${row}\n`
    })

    const blob = new Blob([txtContent], {type: 'text/plain'})
    return new File([blob], 'data.txt', {type: 'text/plain'})
  }
  const handleChange = (selected) => {
    console.log(selected)

    const result = selected.map((option) => ({
      id: option.value,
      sampleId: option.label,
    }))
    setSelectedOptions(result)
  }
  const getData = () => {
    if (debouncedPhenoType === '') {
      setHpoList([])
      return
    }
    if (debouncedPhenoType.length < 3) return
    axios
      .get(`https://ontology.jax.org/api/hp/search?q=${debouncedPhenoType}&max=-1`)
      .then((res) => {
        const termsResponse = res?.data?.terms
        if (!!termsResponse && Array.isArray(termsResponse) && termsResponse.length > 0) {
          setHpoList(termsResponse)
        } else {
          setHpoList([])
        }
      })
  }

  const hpoChange = (event) => {
    setHpoTerms(event.target.value)
    getData()
  }

  useEffect(getData, [debouncedPhenoType])
  useEffect(() => {
    const getSamples = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}samples?sampleType=somatic`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )

        const termsResponse = res?.data
        if (!!termsResponse && Array.isArray(termsResponse) && termsResponse.length > 0) {
          const sampleList = termsResponse.map((item) => {
            return {value: item.id, label: item.sampleId}
          })
          setSampleList(sampleList)
          setLeft(sampleList)
        }
      } catch (error) {
        console.log(error)

        if (error.response) {
          if (error.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Token has expired',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false,
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Something went wrong',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false,
            })
          }
        } else if (error.request) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No response was received',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Error setting up the request: ${error.message}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
          })
        }
      }
    }

    getSamples()
  }, []) // [] dependency array, so it runs only once when the component mounts
  const hideSearchHPO = () => {
    setHpoTerms('')
    setSearchHpo(false)
  }

  const addHpoToPhenotypeList = (id, name) => {
    setPhenoTypeFilters((prevFilters) => [...prevFilters, {id: id, name: name}])
  }
  const submitPhenotype = async () => {
    var phenotypeIds = phenoTypeFilters.map((item) => item.id)

    try {
      const req = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}maf/byPhenotype`,
        {phenotypes: phenotypeIds},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (req.status === 200) {
        const mafFile = convertToTxtFile(req.data)
        setMafData(mafFile)
      }

      setStep(2)
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        Swal.fire({icon: 'error', title: 'Error', text: 'Token has expired'})
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong',
        })
      }
    }
  }

  const submitSamples = async () => {
    setIsLoading(true)
    const chunkSize = 5 // Her bir requestte kaç eleman gönderileceğini belirtiyoruz
    let combinedData = '' // Tüm dönen veriyi string olarak biriktirmek için
    let progress = 0 // Progress bar için başlangıç değeri
    const maxRetries = 3 // İstek başarısız olursa 3 kereye kadar deneme hakkı

    // right dizisini 5'li gruplara ayırıyoruz
    const chunks = []
    for (let i = 0; i < right.length; i += chunkSize) {
      chunks.push(right.slice(i, i + chunkSize))
    }
    var genePoolArray = []
    if (genePool.length > 0) {
      genePoolArray = genePool.split('\n').filter((item) => item.trim() !== '')
      setGenePool(genePoolArray)
    }
    // Swal ile bir progress popup başlatıyoruz
    Swal.fire({
      title: 'Processing...',
      html: `<div style="width: 100%; background-color: #eee;">
               <div id="progress-bar" style="width: 0%; height: 20px; background-color: #76c7c0;"></div>
             </div>
             <p id="progress-text">0% complete</p>`,
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })

    const processChunk = async (chunk, hasHeader, index) => {
      let retries = 0
      let success = false
      while (retries < maxRetries && !success) {
        try {
          // İstek gönderiliyor
          const req = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}maf/bySample`,
            {
              samples: chunk,
              hasHeader,
              detScore: detScore === 0 ? null : detScore,
              genePool: genePoolArray.length > 0 ? genePoolArray : null,
              classificationTypes: selectedTypes.length > 0 ? selectedTypes : null,
            },
            // { samples: chunk, hasHeader },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          )

          // Eğer istek başarılı ise gelen datayı biriktiriyoruz
          if (req.status === 200) {
            const mafFile = req.data
            combinedData += mafFile // Veriyi biriktiriyoruz
            if (index < chunks.length - 1) {
              combinedData += '\n' // Sonuncu değilse bir satır sonu ekliyoruz
            }
            success = true // İstek başarılı oldu
          }
        } catch (error) {
          retries += 1
          if (retries >= maxRetries) {
            return false // 3 denemeden sonra başarısız olursa false döndürüyoruz
          }
        }
      }
      return true // Başarı olursa true döndürüyoruz
    }

    try {
      for (let i = 0; i < chunks.length; i++) {
        const requestOpt = chunks[i].map((item) => {
          return {sampleId: item.label, id: item.value}
        })

        const hasHeader = i === 0

        const isSuccess = await processChunk(requestOpt, hasHeader, i)

        if (!isSuccess) {
          // Başarısız olursa kullanıcıya Retry seçeneği sunuyoruz
          const result = await Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Request failed after 3 attempts. Would you like to retry?',
            showCancelButton: true,
            confirmButtonText: 'Retry',
          })

          // Eğer kullanıcı Retry butonuna tıklarsa chunk'ı tekrar işle
          if (result.isConfirmed) {
            i-- // Aynı chunk'ı tekrar işlemeye devam et
            continue
          } else {
            // Retry yapmazsa işlemi iptal et
            throw new Error('Process aborted by the user')
          }
        }

        // Progress bar güncelleniyor
        progress = ((i + 1) / chunks.length) * 100
        document.getElementById('progress-bar').style.width = `${progress}%`
        document.getElementById('progress-text').innerText = `${Math.round(progress)}% complete`

        setProgressBar(progress) // setProgressBar, progress bar state'ini yönetiyor
      }

      // Tüm istekler tamamlandıktan sonra veriyi dönüştürüp set ediyoruz
      const mafFile = convertToTxtFile(combinedData)
      console.log(combinedData)

      // Tüm veriyi birleştirip txt formatına dönüştürüyoruz
      setMafData(mafFile) // Toplam veriyi tek bir string olarak set ediyoruz

      console.log(combinedData)
      console.log(mafFile)
      setStep(2)

      // İşlem tamamlandıktan sonra Swal popup'ı kapatıyoruz
      Swal.close()
    } catch (error) {
      console.error(error)

      // Eğer işlem iptal edilirse hata mesajı gösteriliyor
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Something went wrong',
      })
    } finally {
      setIsLoading(false)
    }
  }
  // const handleMafUpload = (e) => {
  //   const file = e.target.files[0]
  //   const reader = new FileReader()
  //   setMafData(file) // mafData state'ini güncelle
  //   setStep(2) // İşlemi tamamladığında bir sonraki adıma geç

  //   console.log(file)
  //   // reader.onload = (event) => {
  //   //   const content = event.target.result
  //   //   console.log(content)
  //   // }

  //   // if (file) {
  //   //   reader.readAsText(file)
  //   // }
  // }
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0]
    // const reader = new FileReader()
    console.log(file)
    // if (file && !file.name.endsWith('.maf')) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Invalid File',
    //     text: 'Only .maf files are allowed!',
    //   })
    //   return
    // }
    if (!file) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid File',
        text: 'Only .maf files are allowed!',
      })
      return
    }
    setMafData(file) // mafData state'ini güncelle
    setStep(2) // İşlemi tamamladığında bir sonraki adıma geç

    // reader.onload = (event) => {
    //   const content = event.target.result
    //   setMafData(content) // mafData state'ini güncelle
    //   setStep(2) // İşlemi tamamladığında bir sonraki adıma geç
    // }

    // if (file) {
    //   reader.readAsText(file)
    // }
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    // accept: {
    //   'text/maf': ['.maf'], // Sadece .maf uzantılı dosyaları kabul eder
    // },
  })
  const downloadSamples = async () => {
    setIsLoading(true)
    const chunkSize = 5 // Her bir requestte kaç eleman gönderileceğini belirtiyoruz
    let combinedData = '' // Tüm dönen veriyi string olarak biriktirmek için
    let progress = 0 // Progress bar için başlangıç değeri
    const maxRetries = 3 // İstek başarısız olursa 3 kereye kadar deneme hakkı

    // right dizisini 5'li gruplara ayırıyoruz
    const chunks = []
    for (let i = 0; i < right.length; i += chunkSize) {
      chunks.push(right.slice(i, i + chunkSize))
    }
    var genePoolArray = []
    if (genePool.length > 0) {
      genePoolArray = genePool.split('\n').filter((item) => item.trim() !== '')
      setGenePool(genePoolArray)
    }
    console.log(genePoolArray)
    // Swal ile bir progress popup başlatıyoruz
    Swal.fire({
      title: 'Processing...',
      html: `<div style="width: 100%; background-color: #eee;">
               <div id="progress-bar" style="width: 0%; height: 20px; background-color: #76c7c0;"></div>
             </div>
             <p id="progress-text">0% complete</p>`,
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })

    const processChunk = async (chunk, hasHeader, index) => {
      let retries = 0
      let success = false
      while (retries < maxRetries && !success) {
        try {
          // İstek gönderiliyor
          const req = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}maf/bySample`,
            {
              samples: chunk,
              hasHeader,
              detScore: detScore === 0 ? null : detScore,
              genePool: genePoolArray.length > 0 ? genePoolArray : null,
              classificationTypes: selectedTypes.length > 0 ? selectedTypes : null,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          )

          // Eğer istek başarılı ise gelen datayı biriktiriyoruz
          if (req.status === 200) {
            const mafFile = req.data
            combinedData += mafFile // Veriyi biriktiriyoruz
            if (index < chunks.length - 1) {
              combinedData += '\n' // Sonuncu değilse bir satır sonu ekliyoruz
            }
            success = true // İstek başarılı oldu
          }
        } catch (error) {
          retries += 1
          if (retries >= maxRetries) {
            return false // 3 denemeden sonra başarısız olursa false döndürüyoruz
          }
        }
      }
      return true // Başarı olursa true döndürüyoruz
    }

    try {
      for (let i = 0; i < chunks.length; i++) {
        const requestOpt = chunks[i].map((item) => {
          return {sampleId: item.label, id: item.value}
        })

        const hasHeader = i === 0

        const isSuccess = await processChunk(requestOpt, hasHeader, i)

        if (!isSuccess) {
          const result = await Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Request failed after 3 attempts. Would you like to retry?',
            showCancelButton: true,
            confirmButtonText: 'Retry',
          })

          if (result.isConfirmed) {
            i--
            continue
          } else {
            throw new Error('Process aborted by the user')
          }
        }

        progress = ((i + 1) / chunks.length) * 100
        document.getElementById('progress-bar').style.width = `${progress}%`
        document.getElementById('progress-text').innerText = `${Math.round(progress)}% complete`

        setProgressBar(progress)
      }

      const mafFile = convertToTxtFile(combinedData)
      const blob = new Blob([mafFile], {type: 'text/plain;charset=utf-8'})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'maf_file.maf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      Swal.close()
    } catch (error) {
      console.error(error)

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Something went wrong',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='App'>
      <div
        className='header'
        style={{
          display: step === 1 ? 'flex' : 'none',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px',
          position: 'absolute',
          width: '100%',
        }}
      >
        <img
          src='/morpheuslogo1.png'
          alt='gennext-logo'
          width={150}
          style={{
            marginTop: '-16px',
          }}
        />

        <img src='/gennext-logo-powered-by.png' alt='gennext-logo' width={125} />
      </div>

      {step === 2 && (
        <Heatmap
          heatMapRef={heatMapRef}
          isRendered={isRendered}
          setIsRendered={setIsRendered}
          mafData={mafData}
        />
      )}
      {step === 1 && (
        <div className='page-wrapper bg-img-1 p-t-165 p-b-100'>
          <div className='wrapper wrapper--w680'>
            <div className='card card-1'>
              <div className='card-body'>
                <ul className='tab-list'>
                  <li className='tab-list__item active'>
                    <a className='tab-list__link' href='#tab1' data-toggle='tab'>
                      By Phenotype
                    </a>
                  </li>
                  <li className='tab-list__item'>
                    <a className='tab-list__link' href='#tab2' data-toggle='tab'>
                      By Samples
                    </a>
                  </li>
                  {/* <li className='tab-list__item'>
                    <a className='tab-list__link' href='#tab3' data-toggle='tab'>
                      Upload MAF
                    </a>
                  </li> */}
                  {/*                   
                  <li className="tab-list__item">
               
                    <img src="/morpheuslogo1.png" width={200}/>
                  </li> */}
                </ul>
                <div className='tab-content'>
                  <div
                    className='tab-pane active'
                    id='tab1'
                    style={{
                      padding: ' 44px 55px',
                    }}
                  >
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='input-group'>
                          {!!searchHpo && (
                            <i
                              style={{
                                position: 'absolute',
                                top: '50%',
                                right: '15px',
                              }}
                              className={`clearSearchGeneInput cursor-pointer fa fa-remove`}
                              onClick={() => hideSearchHPO()}
                            ></i>
                          )}
                          <label className='label'>Search Phenotype</label>
                          <input
                            className='input--style-1'
                            type='text'
                            name='address'
                            placeholder={'Search...'}
                            value={hpoTerms}
                            onChange={hpoChange}
                            onFocus={() => {
                              setSearchHpo(true)
                              getData()
                            }}
                          />
                        </div>
                        <div className='searchGeneWrap '>
                          {(searchHpo || hpoTerms !== '') && (
                            <div className='searchResultsWrap' style={{right: '0', width: '100%'}}>
                              {hpoList.length > 0 && (
                                <ul className='list-group'>
                                  {hpoList.map((item, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className='list-group-item'
                                        onClick={() => {
                                          addHpoToPhenotypeList(item.id, item.name)
                                          setSearchHpo(false)
                                          setHpoTerms('')
                                        }}
                                      >
                                        {item.name} : <b style={{fontSize: 10}}>{item.id}sasa</b>
                                      </li>
                                    )
                                  })}
                                </ul>
                              )}
                            </div>
                          )}
                        </div>
                        <button
                          className='btn btn-danger '
                          style={{
                            marginTop: 40,
                            display: 'block',
                            width: '100%',
                          }}
                          onClick={submitPhenotype}
                          disabled={phenoTypeFilters.length === 0}
                        >
                          Submit
                        </button>
                      </div>
                      <div className='col-md-6'>
                        <div className='selected-phenotype mt-3'>
                          <label>Selected Phenotypes</label> <br />
                          {phenoTypeFilters.map((item) => (
                            <span className='badge badge-danger' key={item.id}>
                              {item.name}{' '}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane'
                    id='tab2'
                    style={{
                      padding: ' 44px 55px',
                    }}
                  >
                    <div className='row'>
                      <div className='col-md-12'>
                        <div
                          className='input-group'
                          style={{
                            width: '100%',
                          }}
                        >
                          {/* <label
                            className='label '
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            Search Samples
                          </label> */}
                          {/* <ReactSelect
                            isMulti={true}
                            options={sampleList}
                            onChange={handleChange}
                          /> */}
                          <TransferList
                            left={left}
                            right={right}
                            checked={checked}
                            setChecked={setChecked}
                            setLeft={setLeft}
                            setRight={setRight}
                            sampleList={sampleList}
                          />
                          {/* {sampleList.map((item) => (
                            <span
                              className="badge bg-success"
                              style={{
                                margin: 5,
                                padding: 5,
                                cursor: "pointer",
                              }}
                            >
                              {item.label}{" "}
                            </span>
                          ))} */}
                        </div>
                        {/* <button
                          className="btn btn-danger "
                          style={{
                            marginTop: 40,
                            display: "block",
                            width: "100%",
                          }}
                          onClick={submitSamples}
                          disabled={selectedOptions.length === 0}
                        >
                          Submit
                        </button> */}
                        <div className='col-md-12'>
                          <div
                            className='row'
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-start',
                            }}
                          >
                            <div className='col-md-6'>
                              <div
                                className='buttons-container'
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <div className='select-all'>
                                  <button
                                    onClick={() => {
                                      setChecked(left)
                                    }}
                                    className='btn btn-primary'
                                  >
                                    Select All
                                  </button>
                                </div>
                                <div className='de-select-all'>
                                  <button
                                    onClick={() => {
                                      setChecked([])
                                    }}
                                    className='btn btn-danger'
                                  >
                                    Deselect All
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <div
                                className="buttons-container"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div className="select-all">
                                  <button
                                    onClick={() => {
                                      setRight(left);
                                    }}
                                    className="btn btn-primary"
                                  >
                                    Select All
                                  </button>
                                </div>
                                <div className="de-select-all">
                                  <button
                                    onClick={() => {
                                      setRight([]);
                                    }}
                                    className="btn btn-danger"
                                  >
                                    Deselect All
                                  </button>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          <div className='d-flex gap-5'>
                            <button
                              className='btn btn-danger col-md-6 '
                              style={{
                                marginTop: 40,
                                display: 'block',
                                // width: "100%",
                              }}
                              onClick={submitSamples}
                              disabled={right.length === 0 || isLoading}
                            >
                              {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button
                              className='btn btn-primary col-md-6 '
                              style={{
                                marginTop: 40,
                                display: 'block',
                                // width: "100%",
                              }}
                              onClick={downloadSamples}
                              disabled={right.length === 0 || isLoading}
                            >
                              {isLoading ? 'Loading...' : 'Download MAF'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='advanced-filters '
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        margin: '20px 0',
                        padding: '20px',
                      }}
                    >
                      <button className='btn btn-danger' onClick={openModal}>
                        Advanced Filters
                      </button>
                    </div>
                  </div>
                  <div
                    className='tab-pane'
                    id='tab3'
                    style={{
                      paddingBottom: 0,
                    }}
                  >
                    {/* Upload MAF içeriği */}
                    <div className='upload-maf'>
                      <div
                        {...getRootProps()}
                        style={{
                          border: '2px dashed #ccc',
                          padding: '20px',
                          textAlign: 'center',
                          borderRadius: '10px',
                          backgroundColor: isDragActive ? '#f0f0f0' : '#fff',
                        }}
                      >
                        <img src='/drag-and-drop.png' alt='' width={100} />
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p>Drop the files here...</p>
                        ) : (
                          <p>Drag 'n' drop a MAF file here, or click to select a file</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className='modal-overlay' onClick={closeModal}>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <span className='close-btn' onClick={closeModal}>
              &times;
            </span>

            <h2>Advanced Filters</h2>

            <label
              style={{
                marginTop: 30,
              }}
            >
              {' '}
              Det Score
            </label>
            <div className='slider-container'>
              <div className='values'>
                <div>
                  <input
                    type='number'
                    step='0.1'
                    min={min}
                    max={max}
                    value={detScore}
                    onChange={handleScoreChange}
                  />
                </div>
              </div>
              {/* <h4 className="fw-bold"> DET Score</h4> */}
              <Range
                step={0.1}
                min={min}
                max={max}
                values={[detScore]} // Tek bir değer
                onChange={(values) => setDetScore(values[0])} // Tek bir değeri günceller
                renderTrack={({props, children}) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '5px',
                      width: '100%',
                      background: '#ddd',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        height: '5px',
                        background: '#007bff',
                        left: `${((detScore - min) / (max - min)) * 100}%`,
                        right: `${100 - ((detScore - min) / (max - min)) * 100}%`,
                      }}
                    />
                    {children}
                  </div>
                )}
                renderThumb={({props}) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '16px',
                      width: '16px',
                      backgroundColor: '#007bff',
                      borderRadius: '50%',
                    }}
                  />
                )}
              />
            </div>
            <label>Classification Types</label>
            <div
              className='classification-types  '
              style={{
                display: 'flex',
                gap: 10,
                flexWrap: 'wrap',
              }}
            >
              {classificationTypes.map((type, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '23%',
                    gap: 10,
                  }}
                >
                  <input
                    type='checkbox'
                    id={type}
                    name={type}
                    style={{
                      width: 'auto',
                    }}
                    value={type}
                    checked={selectedTypes.includes(type)}
                    onChange={() => handleCheckboxChange(type)}
                  />
                  <label
                    htmlFor={type}
                    style={{
                      fontWeight: selectedTypes.includes(type) ? 'bold' : 'normal',
                    }}
                  >
                    {type}
                  </label>
                </div>
              ))}
            </div>
            <label
              style={{
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              {' '}
              Gene Pool
            </label>
            <div className='gene-pool'>
              <textarea
                rows='4'
                style={{
                  background: '#F9f9f9',
                  padding: 15,
                  borderRadius: 15,
                  fontSize: 12,
                }}
                cols='50'
                value={genePool}
                onChange={(e) => setGenePool(e.target.value)}
              ></textarea>
            </div>
            <button className='btn-close' onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}

      <style jsx='true'>{`
        .d-flex {
          display: flex;
        }

        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          background-color: white;
          padding: 20px;
          border-radius: 8px;
          width: 800px;
          position: relative;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
          animation: fadeIn 0.3s;
        }

        .close-btn {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
        }
        .slider-container {
          width: 100%;
          margin: auto;
          margin-bottom: 20px;
          display: flex;
          gap: 20px;
          align-items: center;
        }
        .slider-container h4 {
          margin-bottom: 20px;
        }
        .values {
          display: flex;
          justify-content: space-between;
        }

        .values div {
          display: flex;
          align-items: center;
        }

        .values input[type='number'] {
          margin-left: 10px;
          background: #f9f9f9;
          padding: 5px;
          font-size: 12px;
          border: none;
          border-radius: 5px;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        .btn-close {
          background-color: #f9f9f9;
          border: none;
          float: right;
          padding: 10px 20px;
        }
      `}</style>
    </div>
  )
}

export default App
